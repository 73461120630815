import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../context/AlertContext";
import { ConfiguracionContext } from "../../../context/ConfiguracionContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../Spinner";
import { AuthContext } from "../../../context/AuthContext";
import { ServiciosContext } from "../../../context/ServiciosContext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { ScrollPanel } from "primereact/scrollpanel";
import "../../../assets/css/ScrollPanelDemo.css";
import "../../../assets/css/DataTableDemo.css";
import "../../../assets/css/DialogDemo.css";
import ConsultaServicio from "./ConsultaServicio";
import NotasServicio from "./NotasServicio";
import { RadioButton } from "primereact/radiobutton";
import { alignment, defaultDataType } from "export-xlsx";
import ExcelExport from "export-xlsx";
import { jsPDF } from "jspdf";
import { ProgressBar } from "primereact/progressbar";
import { NumericFormat } from "react-number-format";
//import { selectedRowsCountSelector } from "@material-ui/data-grid";
import SearchIcon from "@mui/icons-material/Search";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import throttle from "lodash/throttle";
import parse from "autosuggest-highlight/parse";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import IconButton from "@mui/material/IconButton";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { saveAs } from "file-saver";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMap,
  latLngBound,
} from "react-leaflet";
import "../servicios/Map.css";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../Barra";
import Checkbox2 from "@mui/material/Checkbox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";


const agrupar = [
  {
    id: 1,
    nombre: "Base",
  },
  {
    id: 2,
    nombre: "ColorInicial",
  },
  {
    id: 3,
    nombre: "ColorFinal",
  },
  {
    id: 4,
    nombre: "Antecedente",
  },
  {
    id: 5,
    nombre: "Tratamiento",
  },
  {
    id: 6,
    nombre: "MotivoConsulta",
  },
  {
    id: 7,
    nombre: "Diagnostico",
  },
  {
    id: 8,
    nombre: "IDTitular",
  },
  {
    id: 9,
    nombre: "Documento",
  },
];

export default function Finalizados(props) {
  //const classes = useStyles();
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  let navigate = useNavigate();

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Nombre", dataKey: "nombre" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const [visibleFechas, setVisibleFechas] = useState(false);
  const [fechas, setFechas] = useState(null);
  const [visible, setVisible] = useState(false);
  const [visibleUpload, setVisibleUpload] = useState(false);
  const [visibleModificaciones, setVisibleModificaciones] = useState(false);
  const toast = useRef(null);
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState(null);
  const [servicio, setServicio] = useState(null);
  const [servicioOriginal, guardarServicioOriginal] = useState(null);
  const [grupo, setGrupo] = useState(null);
  const [radio, setRadio] = useState("tomado");
  const [val, setVal] = useState(null);
  const [agrupado, setAgrupado] = useState(null);
  const [progress, setProgress] = useState(false);
  const [indicativo, setIndicativo] = useState(null);
  const [dni, setDni] = useState(null);
  const [usuario, setUsuario] = useState(null);
  const [open, setOpen] = useState(false);
  const [titularId, setTitularId] = useState(null);
  const [titularNombre, setTitularNombre] = useState(null);
  const [titularSeleccionado, setTitularSeleccionado] = useState(null);
  const [bandOpcionesMotivos, setBandOpcionesMotivos] = useState(false);
  const [opcionesMotivos, guardarOpcionesMotivos] = useState([]);
  const [filtroActualizar, guardarFiltroActualizar] = useState(null);
  const [color, setColor] = useState(null);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;


  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    statusAcceso2,
    statusAcceso3,
    motivosConsultas,
    configuracion,
    diagnosticos,
    usuarios,
    colores,
    obtenerAcceso,
    obtenerAcceso2,
    obtenerAcceso3,
    obtenerMotivosConsultas,
    obtenerDiagnosticos,
    obtenerUsuarios,
    obtenerColores,
  } = configuracionContext;

  const serviciosContext = useContext(ServiciosContext);
  const {
    serviciosFinalizados,
    servicioModificado,
    direcciones,
    titular,
    rows,
    notas,
    filtrosListadosServicios,
    serviciosFinalizadosExcel,
    obtenerServiciosFinalizados,
    obtenerNotas,
    guardarServicio,
    uploadFiles,
    guardarFiltrosListadosServicios,
    guardarServicioModificado,
    modificarServicioFinalizado,
    obtenerServicioFinalizado,
    obtenerDirecciones,
    buscarTitular,
    setRows,
    setNotas,
    obtenerDatosTitulares,
    auditarServicio,
  } = serviciosContext;

  useEffect(() => {
    if (state.usuario) {
      obtenerAcceso({ usuarioId: state.usuario.id, layout: "Finalizados" });
      obtenerAcceso2({
        usuarioId: state.usuario.id,
        layout: "UploadHistoriasClinicas",
      });
      obtenerAcceso3({
        usuarioId: state.usuario.id,
        layout: "ModificacionesServicios",
      });
      obtenerUsuarios();
      obtenerColores();
    }
    guardarFiltrosListadosServicios({
      hcServicio: false,
      hcAdjunto: false,
      sinCoseguros: false,
      conCoseguros: false,
      auditados: false,
      noAuditados: false,
      efectivo: false,
      transferencia: false,
      fueraZona: false,
    });
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  if (
    servicioOriginal === null &&
    servicioModificado !== undefined &&
    servicioModificado !== null
  ) {
    guardarServicioOriginal(servicioModificado);
  }

  function formatDate(input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];
    return day + "/" + month + "/" + year;
  }

  const groupBy = (prop) => (data) => {
    return data.reduce((dict, item) => {
      const { [prop]: _, ...rest } = item;
      dict[item[prop]] = [...(dict[item[prop]] || []), rest];
      return dict;
    }, {});
  };

  const buscarAfiliados = () => {
    if (titularNombre !== null && titularNombre !== "") {
      obtenerDatosTitulares({
        opcion: "Apellido y nombre",
        texto: titularNombre,
      });
    }
    if (titularId !== null && titularId !== "") {
      obtenerDatosTitulares({
        opcion: "Número de socio",
        texto: titularId,
      });
    }
  };

  const calendar = (e) => {
    setVisibleFechas(true);
  };

  const exitClick = (e) => {
    navigate(`/`);
  };

  const onHideFechas = (e) => {
    setVisibleFechas(false);
  };

  const onHideUpload = (e) => {
    setVisibleUpload(false);
  };

  const aceptarFechas = (e) => {
    e.preventDefault();
    //Validar

    let fdesde = null;
    let fhasta = null;

    if (indicativo !== null && indicativo === 0) {
      mensajeAlerta(
        "Servicios Finalizados",
        "Indicativo no puede ser cero !.",
        "error"
      );
      return;
    }

    if (dni !== null && dni !== 0 && dni.length <= 3) {
      mensajeAlerta(
        "Servicios Finalizados",
        "Debe ingresar mas numeros del D.N.I !.",
        "error"
      );
      return;
    }

    if (indicativo === null || indicativo.trim() === "") {
      if (
        !fechas ||
        fechas.desde === undefined ||
        fechas.desde === null ||
        fechas.desde.trim() === ""
      ) {
        mensajeAlerta(
          "Servicios Finalizados",
          "Fecha desde es necesaria !.",
          "error"
        );
        return;
      }

      if (
        !fechas ||
        fechas.hasta === undefined ||
        fechas.hasta === null ||
        fechas.hasta.trim() === ""
      ) {
        mensajeAlerta(
          "Servicios Finalizados",
          "Fecha hasta es necesaria !.",
          "error"
        );
        return;
      }

      fdesde = fechas.desde + "T00:00";
      fhasta = fechas.hasta + "T23:59";
    }

    setProgress(true);
    setNotas(null);

    // Llamar al context
    obtenerServiciosFinalizados({
      fdesde: fdesde,
      fhasta: fhasta,
      filtros: filtrosListadosServicios,
      radio,
      indicativo,
      dni,
      usuario,
      titularSeleccionado,
      color
    });

    guardarFiltroActualizar({
      fdesde: fdesde,
      fhasta: fhasta,
      filtros: filtrosListadosServicios,
      radio,
      indicativo,
      dni,
      usuario,
      titularSeleccionado,
      color
    })

    onHideFechas();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const idTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">
          {configuracion && configuracion.destinatario !== 2
            ? "Indicativo"
            : "Ind. - Id"}
        </span>
        {configuracion && configuracion.destinatario !== 2
          ? rowData.id
          : rowData.indicativoId}
      </Fragment>
    );
  };

  const indicativoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Indicativo</span>
        {rowData.indicativo}
      </Fragment>
    );
  };

  const nombreTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre</span>
        {rowData.nombre}
      </Fragment>
    );
  };

  const colorInicialTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Color Inicial</span>
        {rowData.colorInicio}
      </Fragment>
    );
  };

  const colorFinalTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Color Final</span>
        {rowData.color}
      </Fragment>
    );
  };

  const titularIdTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Socio Titular</span>
        {rowData.titularId}
      </Fragment>
    );
  };

  const nombreTitularTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Nombre Titular</span>
        {rowData.nombreTitular}
      </Fragment>
    );
  };

  const TomadoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Tomado</span>
        {rowData.tomado}
      </Fragment>
    );
  };

  const PasadoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Pasado</span>
        {rowData.pasado}
      </Fragment>
    );
  };

  const SalidaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Salida</span>
        {rowData.salida}
      </Fragment>
    );
  };

  const LlegadaTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Llegada</span>
        {rowData.llegada}
      </Fragment>
    );
  };

  const FinTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Fin</span>
        {rowData.fin}
      </Fragment>
    );
  };

  const MovilTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Móvil</span>
        {rowData.movilId}
      </Fragment>
    );
  };

  const TripulacionTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Tripulación</span>
        {rowData.tripulacionId}
      </Fragment>
    );
  };

  const DomicilioTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Domicilio</span>
        {rowData.domicilio}
      </Fragment>
    );
  };

  const DocumentoTemplate = (rowData) => {
    return (
      <Fragment>
        <span className="p-column-title">Documento</span>
        {rowData.numeroDocumento}
      </Fragment>
    );
  };

  const consultaSocio = (rowData) => {
    guardarServicio({
      ...rowData,
      origen: "despachos",
    });
    //setVisible(true);
  };

  const auditar = (rowData) => {
    toast.current.show({
      severity: "warn",
      sticky: true,
      content: (
        <div
          className="p-flex p-flex-column"
          style={{ flex: "1", marginLeft: "1em" }}
        >
          <div className="p-text-center">
            <i
              className="pi pi-exclamation-triangle"
              style={{ fontSize: "3rem" }}
            ></i>
            <h4>
              {!rowData.auditado
                ? `Confirma como auditado el ID ${rowData.id} ?`
                : `El ID ${rowData.id} ya esta marcado como auditado. Desea cambiarlo como NO auditado ?`}
            </h4>
            <p>{`Nombre : ${rowData.nombre} del Titular ${rowData.nombreTitular}`}</p>
          </div>
          <div className="p-grid p-fluid">
            <div className="p-col-6">
              <Button
                type="button"
                label="Si"
                className="p-button-success"
                onClick={(e) => opcionSiAuditar(rowData.id, rowData.auditado)}
              />
            </div>
            <div className="p-col-6">
              <Button
                type="button"
                label="No"
                className="p-button-danger"
                style={{ marginTop: "1em" }}
                onClick={opcionNo}
              />
            </div>
          </div>
        </div>
      ),
    });
  };

  const opcionSiAuditar = (id, estado) => {
    auditarServicio({ servicioId: id, estado });
    opcionNo();
    let elem = document.getElementById("botonAceptarFechas");
    setTimeout(() => {
      elem.click();
    }, 1000);
  };

  function opcionNo() {
    toast.current.clear();
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <Fragment>
        <Button
          style={{
            margin: 0,
            padding: 0,
          }}
          icon="pi pi-search"
          className="p-button-success p-button-text"
          onClick={() => {
            setSelectedRow(rowData);
            consultaSocio(rowData);
            obtenerNotas({ servicioId: rowData.id });
            setVisible(true);
          }}
          tooltip="Datos del servicio"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: "tooltip",
          }}
        />
        <Button
          style={{
            margin: 0,
            padding: 0,
            color: rowData.hcServicio === null ? "black" : "red",
          }}
          icon="pi pi-file-pdf"
          className="p-button-text"
          onClick={
            rowData.hcServicio !== null
              ? () => window.open(rowData.hcServicio, "_blank")
              : null
          }
          disabled={rowData.hcServicio === null}
          tooltip="H.C. Servicio"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: "tooltip",
          }}
        />
        <Button
          style={{
            margin: 0,
            padding: 0,
            color: rowData.hcAdjunto === null ? "black" : "red",
          }}
          icon="pi pi-file-pdf"
          className="p-button-text"
          onClick={
            rowData.hcAdjunto === null
              ? () => {
                  setServicio(rowData);
                  subirAdjunto();
                }
              : () => window.open(rowData.hcAdjunto, "_blank")
          }
          //disabled={rowData.hcAdjunto === null}
          tooltip="H.C. Adjunto"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: "tooltip",
          }}
        />
        <Button
          style={{
            margin: 0,
            padding: 0,
          }}
          icon="pi pi-pencil"
          className="p-button-text"
          onClick={(e) => {
            guardarServicioModificado(null);
            guardarServicioOriginal(null);
            setBandOpcionesMotivos(false);
            guardarOpcionesMotivos([]);
            modificaciones(rowData);
          }}
          tooltip="Modificaciones"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: "tooltip",
          }}
        />

        <Button
          style={{
            margin: 0,
            padding: 0,
            color: rowData.auditado === null ? "black" : "green",
          }}
          icon="pi pi-check"
          className="p-button-text"
          onClick={(e) => auditar(rowData)}
          tooltip="Auditar"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
            className: "tooltip",
          }}
        />
      </Fragment>
    );
  };

  const exportPdf = () => {
    import("jspdf").then((jsPDF) => {
      import("jspdf-autotable").then(() => {
        const doc = new jsPDF.default(0, 0);
        doc.autoTable(exportColumns, serviciosFinalizados);
        doc.save("serviciosFinalizados.pdf");
      });
    });
  };

  const exportExcel = () => {
    if (serviciosFinalizadosExcel && serviciosFinalizadosExcel.length > 0) {
      import("xlsx").then((xlsx) => {
        const worksheet = xlsx.utils.json_to_sheet(serviciosFinalizadosExcel);
        const workbook = {
          Sheets: { data: worksheet },
          SheetNames: ["data"],
        };
        const excelBuffer = xlsx.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        saveAsExcelFile(excelBuffer, "serviciosFinalizados");
      });
    }
  };

  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((FileSaver) => {
      let EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      let EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(
        data,
        fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
      );
    });
  };

  const header = (
    <Fragment>
      <div className="p-d-flex export-buttons">
        <Button
          type="button"
          icon="pi pi-file-excel"
          onClick={exportExcel}
          className="p-button-success p-mr-2"
          data-pr-tooltip="XLS"
        />
        {/*
        <Button
          type="button"
          icon="pi pi-file-pdf"
          onClick={exportPdf}
          className="p-button-warning p-mr-2"
          data-pr-tooltip="PDF"
          style={{ marginRight: "1em" }}
        />
        */}
        <span
          style={{ marginLeft: "1em", marginRight: "1em" }}
          className="p-input-icon-left"
        >
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => setGlobalFilter(e.target.value)}
            placeholder="Buscar"
          />
        </span>
        <Tag value="Servicios Finalizados"></Tag>
      </div>
    </Fragment>
  );

  const renderHeaderFechas = () => {
    return (
      <div>
        <Tag value="Servicios Finalizados"></Tag>
      </div>
    );
  };

  const renderHeaderUpload = () => {
    return (
      <div>
        <Tag value="Upload de Archivos"></Tag>
      </div>
    );
  };

  const renderFooterFechas = () => {
    return (
      <div>
        <ButtonMui
          onClick={(e) => aceptarFechas(e)}
          color="primary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CheckIcon />}
        >
          Aceptar
        </ButtonMui>
        <ButtonMui
          onClick={onHideFechas}
          color="secondary"
          type="button"
          fullWidth
          variant="contained"
          startIcon={<CloseIcon />}
          autoFocus
        >
          Cancelar
        </ButtonMui>
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <Fragment>
        <div align="left">
          <Tag value="Datos del Servicio"></Tag>
        </div>
      </Fragment>
    );
  };

  const onHide = (e) => {
    setSelectedRow(null);
    setVisible(false);
  };

  const subirAdjunto = () => {
    if (statusAcceso2 !== 200) {
      mensajeAlerta(
        "Accesos",
        "No tiene permiso para acceder a esta pantalla !.",
        "error"
      );
      return;
    }
    setVisibleUpload(true);
  };

  const modificaciones = (rowData) => {
    //let date = new Date();
    let newDate = new Date().toISOString();

    //console.log(newDate.toLocaleString("es-AR"))
    //console.log(rowData.tomado)

    var datearray = rowData.fin.split("/");
    var newYear1 = new Date(newDate.toLocaleString("es-AR"));
    var newYear2 = new Date(
      datearray[1] + "/" + datearray[0] + "/" + datearray[2]
    );

    var dif = newYear1 - newYear2;
    var dif = Math.round(dif / 1000 / 60);

    if (statusAcceso3 !== 200) {
      mensajeAlerta(
        "Accesos",
        "No tiene permiso para acceder a esta pantalla !.",
        "error"
      );
      return;
    }

    if (
      dif > 1440 &&
      state.usuario.role !== "a" &&
      configuracion.destinatario === 1
    ) {
      toast.current.show({
        severity: "error",
        summary: "El final ya ha superado las 24 hs de finalización.",
        life: 2000,
      });
      return;
    }

    obtenerMotivosConsultas({ todos: false });
    obtenerDiagnosticos({ todos: false });
    obtenerServicioFinalizado({ servicioId: rowData.id });
    setVisibleModificaciones(true);
  };

  const onChange = (e) => {
    setFile(e.target.files[0]);
    setFilename(e.target.files[0].name);
  };

  const uploadFile = async (e) => {
    e.preventDefault();

    console.log(file)
    return


    const formData = new FormData();
    formData.append("file", file);
    formData.append("servicioId", servicio.id);

    uploadFiles(formData);
    setVisibleUpload(false);
    let fdesde = fechas.desde + "T00:00";
    let fhasta = fechas.hasta + "T23:59";
    setFile(null);
    setFilename(null);
    setTimeout(() => {
      obtenerServiciosFinalizados({
        fdesde: fdesde,
        fhasta: fhasta,
        filtros: filtrosListadosServicios,
        radio,
      });
      onHideFechas();
    }, 3000);
  };

  const renderHeaderModificar = () => {
    return (
      <div>
        <Tag value="Modificaciones del Servicio"></Tag>
      </div>
    );
  };

  const renderFooterModificar = () => {
    return (
      <div>
        <Button
          label="Confirmar"
          icon="pi pi-check"
          onClick={() => handleModificar()}
          className="p-button-text"
          autoFocus
        />
        <Button
          label="Volver"
          icon="pi pi-times"
          onClick={(e) => handleCloseModificar()}
          className="p-button-text"
        />
        <Button
          id="clickTitular"
          label="click"
          icon="pi pi-times"
          onClick={() => guardarTitular()}
          className="p-button-text"
          style={{ visibility: "hidden" }}
        />
      </div>
    );
  };

  const handleTitularBlur = (event) => {
    const tit = event.target.value.padStart(8, 0);
    buscarTitular({ titularId: tit });
    setTimeout(() => {
      let elem = document.getElementById("clickTitular");
      elem.click();
    }, 1000);
  };

  const guardarTitular = () => {
    guardarServicioModificado({
      ...servicioModificado,
      titularId: titular.id_titular ? titular.id_titular : null,
      nombreTitular: titular.nombre_razonsocial
        ? titular.nombre_razonsocial
        : null,
      afiliadoIdTitular: titular.id ? titular.id : null,
      grupoId: titular.id_grupo ? titular.id_grupo : null,
      planId: titular.id_plan_facturacion ? titular.id_plan_facturacion : null,
    });
  };

  const handleCloseModificar = () => {
    setVisibleModificaciones(false);
  };

  const handleModificar = () => {
    if (
      !servicioModificado ||
      servicioModificado.titularId === undefined ||
      servicioModificado.titularId === null ||
      servicioModificado.titularId.trim() === "" ||
      servicioModificado.titularId.length !== 8
    ) {
      toast.current.show({
        severity: "warn",
        summary: "El Nº de titular es necesario !.",
        life: 2000,
      });
      return;
    }

    if (
      !servicioModificado ||
      servicioModificado.nombreTitular === undefined ||
      servicioModificado.nombreTitular === null ||
      servicioModificado.nombreTitular.trim() === ""
    ) {
      toast.current.show({
        severity: "warn",
        summary: "El nombre de titular es necesario !.",
        life: 2000,
      });
      return;
    }

    if (
      !servicioModificado ||
      servicioModificado.numeroDocumento === undefined ||
      servicioModificado.numeroDocumento === null ||
      servicioModificado.numeroDocumento.trim() === "" ||
      servicioModificado.numeroDocumento.length < 2
    ) {
      toast.current.show({
        severity: "warn",
        summary: "El D.N.I es necesario !.",
        life: 2000,
      });
      return;
    }

    if (
      !servicioModificado ||
      servicioModificado.nombre === undefined ||
      servicioModificado.nombre === null ||
      servicioModificado.nombre.trim() === "" ||
      servicioModificado.nombre.length < 2
    ) {
      toast.current.show({
        severity: "warn",
        summary: "El apellido y nombre es necesario !.",
        life: 2000,
      });
      return;
    }

    if (
      !servicioModificado ||
      servicioModificado.telefono === undefined ||
      servicioModificado.telefono === null ||
      servicioModificado.telefono.trim() === "" ||
      servicioModificado.telefono.length < 2
    ) {
      toast.current.show({
        severity: "warn",
        summary: "El teléfono es necesario !.",
        life: 2000,
      });
      return;
    }
    if (
      !servicioModificado ||
      servicioModificado.domicilio === undefined ||
      servicioModificado.domicilio === null ||
      servicioModificado.domicilio.trim() === "" ||
      servicioModificado.domicilio.length < 2
    ) {
      toast.current.show({
        severity: "warn",
        summary: "El domicilio es necesario !.",
        life: 2000,
      });
      return;
    }

    if (
      !servicioModificado ||
      servicioModificado.localidad === undefined ||
      servicioModificado.localidad === null ||
      servicioModificado.localidad.trim() === "" ||
      servicioModificado.localidad.length < 2
    ) {
      toast.current.show({
        severity: "warn",
        summary: "La localidad es necesaria !.",
        life: 2000,
      });
      return;
    }

    if (
      !servicioModificado ||
      servicioModificado.edad === undefined ||
      servicioModificado.edad === null ||
      servicioModificado.edad === ""
    ) {
      toast.current.show({
        severity: "warn",
        summary: "La edad es necesaria !.",
        life: 2000,
      });
      return;
    }

    if (
      !servicioModificado ||
      servicioModificado.motivoConsultaNombre === undefined ||
      servicioModificado.motivoConsultaNombre === null ||
      servicioModificado.motivoConsultaNombre.trim() === "" ||
      servicioModificado.motivoConsultaNombre.length < 2
    ) {
      toast.current.show({
        severity: "warn",
        summary: "El motivo de consulta es necesario !.",
        life: 2000,
      });
      return;
    }

    //console.log(servicioModificado);

    if (
      !servicioModificado ||
      servicioModificado.diagnosticoNombre === undefined ||
      servicioModificado.diagnosticoNombre === null ||
      servicioModificado.diagnosticoNombre.trim() === "" ||
      servicioModificado.diagnosticoNombre.length < 2
    ) {
      toast.current.show({
        severity: "warn",
        summary: "El diagnóstico es necesario !.",
        life: 2000,
      });
      return;
    }

    if (
      servicioModificado &&
      servicioModificado.celular !== null &&
      servicioModificado.celular !== "" &&
      servicioModificado.celular.length !== 10
    ) {
      toast.current.show({
        severity: "warn",
        summary: "El WhatsApp debe tener 10 digitos !.",
        life: 2000,
      });
      return;
    }

    if (configuracion.destinatario === 4) {

      if (
        !servicioModificado ||
        servicioModificado.tomado === undefined ||
        servicioModificado.tomado === null ||
        servicioModificado.tomado === ""
      ) {
        toast.current.show({
          severity: "warn",
          summary: "El horario de tomado es necesario !.",
          life: 2000,
        });
        return;
      }

      if (
        !servicioModificado ||
        servicioModificado.pasado === undefined ||
        servicioModificado.pasado === null ||
        servicioModificado.pasado === ""
      ) {
        toast.current.show({
          severity: "warn",
          summary: "El horario de pasado es necesario !.",
          life: 2000,
        });
        return;
      }

      if (
        !servicioModificado ||
        servicioModificado.salida === undefined ||
        servicioModificado.salida === null ||
        servicioModificado.salida === ""
      ) {
        toast.current.show({
          severity: "warn",
          summary: "El horario de salida es necesario !.",
          life: 2000,
        });
        return;
      }

      if (
        !servicioModificado ||
        servicioModificado.llegada === undefined ||
        servicioModificado.llegada === null ||
        servicioModificado.llegada === ""
      ) {
        toast.current.show({
          severity: "warn",
          summary: "El horario de llegada es necesario !.",
          life: 2000,
        });
        return;
      }

      if (
        !servicioModificado ||
        servicioModificado.fin === undefined ||
        servicioModificado.fin === null ||
        servicioModificado.fin === ""
      ) {
        toast.current.show({
          severity: "warn",
          summary: "El horario de final es necesario !.",
          life: 2000,
        });
        return;
      }
    }

    modificarServicioFinalizado({
      servicioId: servicioModificado.id,
      servicioOriginal,
      servicioModificado,
      usuario: state.usuario.usuario,
      destinatario: configuracion.destinatario,
      //coseguroAbonar,
      //servicioAnterior: servicioSeleccionado,
    });

    handleCloseModificar();
    setTimeout(() => {
      obtenerServiciosFinalizados({
        fdesde: filtroActualizar.fdesde,
        fhasta: filtroActualizar.fhasta,
        filtros: filtroActualizar.filtrosListadosServicios,
        radio: filtroActualizar.radio,
        indicativo: filtroActualizar.indicativo,
        dni: filtroActualizar.dni,
        usuario: filtroActualizar.usuario,
        titularSeleccionado: filtroActualizar.titularSeleccionado,
        color: filtroActualizar.color,
      });
    }, 1000);

    //exitClick();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };


  const handleClickUp = () => {
    if (servicioModificado.color === "VERDE") {
      guardarServicioModificado({
        ...servicioModificado,
        color: "AMARILLO"
      })
    }
    if (servicioModificado.color === "AMARILLO") {
      guardarServicioModificado({
        ...servicioModificado,
        color: "ROJO"
      })
    }
  };

  function handleEnter(event) {
    if (event.keyCode === 13) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "titularId":
          campo = document.getElementById("dni");
          campo.focus();
          break;
        case "dni":
          campo = document.getElementById("nombre");
          campo.focus();
          break;
        case "nombre":
          campo = document.getElementById("telefono");
          campo.focus();
          break;
        case "telefono":
          campo = document.getElementById("provincia");
          campo.focus();
          break;
        case "provincia":
          campo = document.getElementById("domicilio");
          campo.focus();
          break;
        case "domicilio":
          campo = document.getElementById("domicilioGeo");
          campo.focus();
          break;
        case "domicilioGeo":
          campo = document.getElementById("entre");
          campo.focus();
          break;
        case "entre":
          campo = document.getElementById("localidad");
          campo.focus();
          break;
        case "localidad":
          campo = document.getElementById("edad");
          campo.focus();
          break;
        case "edad":
          campo = document.getElementById("motivoConsulta");
          campo.focus();
          break;
        case "motivoConsulta":
          campo = document.getElementById("operadorEmisor");
          campo.focus();
          break;
        case "operadorEmisor":
          campo = document.getElementById("indicativoEmisor");
          campo.focus();
          break;
        case "indicativoEmisor":
          campo = document.getElementById("obraSocial");
          campo.focus();
          break;
        case "obraSocial":
          campo = document.getElementById("nroObraSocial");
          campo.focus();
          break;
        case "nroObraSocial":
          campo = document.getElementById("diagnostico");
          campo.focus();
          break;
        case "diagnostico":
          campo = document.getElementById("detalleDiagnostico");
          campo.focus();
          break;
        case "coseguroAbonar":
          campo = document.getElementById("coseguroAbonado");
          campo.focus();
          break;
        case "coseguroAbonado":
          campo = document.getElementById("covid");
          campo.focus();
          break;
        case "covid":
          campo = document.getElementById("fueraZona");
          campo.focus();
          break;
        case "fueraZona":
          campo = document.getElementById("celular");
          campo.focus();
          break;
        case "celular":
          campo = document.getElementById("videollamada");
          campo.focus();
          break;
        case "videollamada":
          campo = document.getElementById("observaciones");
          campo.focus();
          break;
      }
    }

    if (event.keyCode === 27) {
      let element = event.target.id;
      let campo = null;

      switch (element) {
        case "dni":
          campo = document.getElementById("titularId");
          campo.focus();
          break;
        case "nombre":
          campo = document.getElementById("dni");
          campo.focus();
          break;
        case "telefono":
          campo = document.getElementById("nombre");
          campo.focus();
          break;
        case "provincia":
          campo = document.getElementById("telefono");
          campo.focus();
          break;
        case "domicilio":
          campo = document.getElementById("provincia");
          campo.focus();
          break;
        case "domicilioGeo":
          campo = document.getElementById("domicilio");
          campo.focus();
          break;
        case "entre":
          campo = document.getElementById("domicilioGeo");
          campo.focus();
          break;
        case "localidad":
          campo = document.getElementById("entre");
          campo.focus();
          break;
        case "edad":
          campo = document.getElementById("localidad");
          campo.focus();
          break;
        case "motivoConsulta":
          campo = document.getElementById("edad");
          campo.focus();
          break;
        case "operadorEmisor":
          campo = document.getElementById("motivoConsulta");
          campo.focus();
          break;
        case "indicativoEmisor":
          campo = document.getElementById("operadorEmisor");
          campo.focus();
          break;
        case "obraSocial":
          campo = document.getElementById("indicativoEmisor");
          campo.focus();
          break;
        case "nroObraSocial":
          campo = document.getElementById("obraSocial");
          campo.focus();
          break;
        case "covid":
          campo = document.getElementById("coseguro");
          campo.focus();
          break;
        case "diagnostico":
          campo = document.getElementById("nroObraSocial");
          campo.focus();
          break;
        case "detalleDiagnostico":
          campo = document.getElementById("diagnostico");
          campo.focus();
          break;
        case "coseguroAbonar":
          campo = document.getElementById("detalleDiagnostico");
          campo.focus();
          break;
        case "coseguroAbonado":
          campo = document.getElementById("coseguroAbonar");
          campo.focus();
          break;
        case "fueraZona":
          campo = document.getElementById("covid");
          campo.focus();
          break;
        case "celular":
          campo = document.getElementById("fueraZona");
          campo.focus();
          break;
        case "videollamada":
          campo = document.getElementById("celular");
          campo.focus();
          break;
        case "observaciones":
          campo = document.getElementById("videollamada");
          campo.focus();
          break;
      }
    }
  }

  function clearLocalidad() {
    guardarServicioModificado({
      ...servicioModificado,
      localidad: "",
    });
  }

  const handleInputBlur = (event) => {
    if (event.relatedTarget.id === "provincia") {
      return;
    }

    /*
    const close = document.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];
    setTimeout(() => {
      close.click();
    }, 100);
    */
    clearLocalidad();
    obtenerDirecciones({
      domicilio: servicioModificado.domicilio.toLowerCase(),
      provincia: servicioModificado.provincia.toLowerCase(),
    });
  };

  function PopupExample(e) {
    if (
      !servicioModificado ||
      servicioModificado.lat === undefined ||
      servicioModificado.lat === null ||
      servicioModificado.lon === undefined ||
      servicioModificado.lon === null
    ) {
      return;
    }

    let position = [];
    position.push(servicioModificado.lat);
    position.push(servicioModificado.lon);

    return (
      <MapContainer
        center={position}
        zoom={15}
        style={{ height: "60vh", width: "60vh" }}
      >
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}></Marker>
      </MapContainer>
    );
  }

  /* INICIO */

  if (
    !bandOpcionesMotivos &&
    motivosConsultas &&
    motivosConsultas.length > 0 &&
    servicioModificado &&
    configuracion
  ) {
    setBandOpcionesMotivos(true);
    let array = [];

    if (configuracion && (configuracion.destinatario === 1 || configuracion.destinatario === 3 || configuracion.destinatario === 4)) {
      if (
        servicioModificado.color === "VERDE" ||
        servicioModificado.color === "AMARILLO" ||
        servicioModificado.color === "ROJO"
      ) {
        array = motivosConsultas.filter(
          (item) =>
            item.colorId === 3 || item.colorId === 4 || item.colorId === 5
        );
      }
    }

    if (configuracion && configuracion.destinatario === 2) {
      if (
        servicioModificado.color === "VERDE" ||
        servicioModificado.color === "AMARILLO" ||
        servicioModificado.color === "ROJO" ||
        servicioModificado.color === "CELESTE"
      ) {
        array = motivosConsultas.filter(
          (item) =>
            item.colorId === 3 ||
            item.colorId === 4 ||
            item.colorId === 5 ||
            item.colorId === 12
        );
      }
    }

    if (servicioModificado.color === "NEGRO") {
      array = motivosConsultas.filter((item) => item.colorId === 1);
    }

    if (servicioModificado.color === "BLANCO") {
      array = motivosConsultas.filter((item) => item.colorId === 2);
    }

    if (servicioModificado.color === "AZUL") {
      array = motivosConsultas.filter((item) => item.colorId === 6);
    }

    if (configuracion && configuracion.destinatario === 1) {
      if (servicioModificado.color === "CELESTE") {
        array = motivosConsultas.filter((item) => item.colorId === 9);
      }
    }
    if (servicioModificado.color === "FUCCIA") {
      array = motivosConsultas.filter((item) => item.colorId === 7);
    }
    if (servicioModificado.color === "NARANJA") {
      array = motivosConsultas.filter((item) => item.colorId === 7);
    }

    guardarOpcionesMotivos(array);
  }

  /*FIN*/

  //console.log(filtroActualizar)
  //console.log(selectedRow)

  return (
    <Fragment>
      <Barra />
      <Toast ref={toast} />
      {statusAcceso !== 200 ? <Spinner /> : null}
      {statusAcceso === 200 ? (
        <Fragment>
          <Grid id="dataTableBotones" xs={12} sm={12} md={12}>
            <Button
              icon="pi pi-calendar"
              tooltip="Filtros"
              className="p-button-rounded p-button-help"
              style={{ marginLeft: "0em" }}
              onClick={calendar}
            />
            <Button
              icon="pi pi-times"
              tooltip="Cerrar"
              className="p-button-rounded p-button-primary"
              style={{ marginLeft: "1em" }}
              onClick={exitClick}
            />
            <Button
              id="botonAceptarFechas"
              onClick={(e) => aceptarFechas(e)}
              label="click"
              icon="pi pi-times"
              className="p-button-text"
              style={{ visibility: "hidden" }}
            />

            {progress && !serviciosFinalizados ? (
              <ProgressBar
                mode="indeterminate"
                style={{ height: "6px" }}
              ></ProgressBar>
            ) : null}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              style={{ align: "center", margin: "1em" }}
            />
            <div className="datatable-responsive-demo pdatatable2">
              <div className="card">
                <Tooltip target=".export-buttons>button" position="bottom" />
                <DataTable
                  value={serviciosFinalizados}
                  selection={selectedRow}
                  onSelectionChange={(e) => {
                    if (selectedRow !== null) {
                      if (selectedRow.id == e.value.id) {
                        setSelectedRow(null);
                      } else {
                        setSelectedRow(e.value);
                      }
                    } else {
                      setSelectedRow(e.value);
                    }
                  }}
                  /*  
                  onSelectionChange={(e) => {
                    console.log('si')
                    setSelectedRow(e.value);
                    //guardarServicio(null);
                  }}
                  */
                  selectionMode="single"
                  dataKey="id"
                  header={header}
                  className="p-datatable-gridlines p-datatable-sm p-datatable-responsive-demo"
                  style={{ fontSize: "xx-small" }}
                  globalFilter={globalFilter}
                  emptyMessage="No hay datos."
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                  rows={10}
                  rowsPerPageOptions={[10, 20, 50]}
                  //paginatorLeft={paginatorLeft}
                  //paginatorRight={paginatorRight}
                >
                  <Column
                    field={
                      configuracion && configuracion.destinatario !== 2
                        ? "id"
                        : "indicativoId"
                    }
                    header={
                      configuracion && configuracion.destinatario !== 2
                        ? "indicativo"
                        : "Ind. - Id"
                    }
                    className="colIndicativo"
                    body={idTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="nombre"
                    header="Nombre"
                    className="colNombre"
                    body={nombreTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="colorInicio"
                    header="Color Inicial"
                    className="colColor"
                    body={colorInicialTemplate}
                    sortable
                    //filter
                    //filterPlaceholder="Color"
                  ></Column>
                  <Column
                    field="color"
                    header="Color Final"
                    className="colColor"
                    body={colorFinalTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="titularId"
                    header="Socio Titular"
                    className="colMovil"
                    body={titularIdTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="nombreTitular"
                    header="Nombre Titular"
                    className="colNombre"
                    body={nombreTitularTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="tomado"
                    header="Tomado"
                    className="colHorarios"
                    body={TomadoTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="pasado"
                    header="Pasado"
                    className="colHorarios"
                    body={PasadoTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="salida"
                    header="Salida"
                    className="colHorarios"
                    body={SalidaTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="llegada"
                    header="Llegada"
                    className="colHorarios"
                    body={LlegadaTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="fin"
                    header="Fin"
                    className="colHorarios"
                    body={FinTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="movilId"
                    header="Móvil"
                    className="colMovil"
                    body={MovilTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="tripulacionId"
                    header="Tripulación"
                    className="colMovil"
                    body={TripulacionTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="domicilio"
                    header="Domicilio"
                    className="colDomicilio"
                    body={DomicilioTemplate}
                    sortable
                  ></Column>
                  <Column
                    field="numeroDocumento"
                    header="Documento"
                    className="colMovil"
                    body={DocumentoTemplate}
                    sortable
                  ></Column>
                  <Column
                    className="colBotones"
                    body={actionBodyTemplate}
                  ></Column>
                </DataTable>
              </div>
            </div>
          </Grid>

          <Dialog
            header={renderHeaderFechas()}
            visible={visibleFechas}
            closable={false}
            footer={renderFooterFechas()}
            breakpoints={{ "960px": "75vw" }}
            style={{ width: "65vw", paddingBottom: "0" }}
            position="center"
          >
            <Grid
              container
              spacing={12}
              style={{ marginTop: "1vw", marginLeft: "0.5vw" }}
            >
              <label htmlFor="tomado">F. Tomado</label>
              <RadioButton
                inputId="tomado"
                value="tomado"
                name="fecha"
                onChange={(e) => setRadio(e.value)}
                checked={radio === "tomado"}
                style={{ marginTop: "0.2vw", marginLeft: "0.5vw" }}
              />
              <label style={{ marginLeft: "1vw" }} htmlFor="pasado">
                F. Pasado
              </label>
              <RadioButton
                inputId="pasado"
                value="pasado"
                name="fecha"
                onChange={(e) => setRadio(e.value)}
                checked={radio === "pasado"}
                style={{ marginTop: "0.2vw", marginLeft: "0.5vw" }}
              />
              <label style={{ marginLeft: "1vw" }} htmlFor="salida">
                F. Salida
              </label>
              <RadioButton
                inputId="salida"
                value="salida"
                name="fecha"
                onChange={(e) => setRadio(e.value)}
                checked={radio === "salida"}
                style={{ marginTop: "0.2vw", marginLeft: "0.5vw" }}
              />
              <label style={{ marginLeft: "1vw" }} htmlFor="llegada">
                F. Llegada
              </label>
              <RadioButton
                inputId="llegada"
                value="llegada"
                name="fecha"
                onChange={(e) => setRadio(e.value)}
                checked={radio === "llegada"}
                style={{ marginTop: "0.2vw", marginLeft: "0.5vw" }}
              />
              <label style={{ marginLeft: "1vw" }} htmlFor="fin">
                F. Fin
              </label>
              <RadioButton
                inputId="fin"
                value="fin"
                name="fecha"
                onChange={(e) => setRadio(e.value)}
                checked={radio === "fin"}
                style={{ marginTop: "0.2vw", marginLeft: "0.5vw" }}
              />
            </Grid>
            <Grid container spacing={3} style={{ marginTop: "1vw" }}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="desde"
                  label="Desde"
                  type="date"
                  fullWidth
                  variant="standard"
                  //onKeyDown={handleEnter}
                  value={fechas && fechas.desde ? fechas.desde : null}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFechas({
                      ...fechas,
                      desde: e.target.value,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="hasta"
                  label="Hasta"
                  type="date"
                  fullWidth
                  variant="standard"
                  //onKeyDown={handleEnter}
                  value={fechas && fechas.hasta ? fechas.hasta : null}
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setFechas({
                      ...fechas,
                      hasta: e.target.value,
                    });
                  }}
                />
              </Grid>

              {/*    
              <Grid
                item
                xs={6}
                md={6}
                style={{ paddingLeft: 0, marginTop: "0em" }}
              >
                <Autocomplete
                  id="agrupar"
                  fullWidth
                  disabled={
                    filtrosListadosServicios &&
                    (filtrosListadosServicios.hcServicio ||
                      filtrosListadosServicios.hcAdjunto ||
                      filtrosListadosServicios.sinCoseguros ||
                      filtrosListadosServicios.conCoseguros)
                  }
                  options={agrupar}
                  defaultValue={grupo ? agrupar[grupo - 1] : null}
                  value={val}
                  disablePortal
                  getOptionLabel={(option) => option.nombre}
                  renderInput={(params) => (
                    <TextField {...params} label="Agrupar por" />
                  )}
                  //onKeyDown={handleEnter}
                  onChange={(event, value) => {
                    if (value) {
                      setGrupo(value.id);
                      setVal(value);
                    } else {
                      setGrupo(null);
                      setVal(null);
                    }
                  }}
                />
              </Grid>
              */}

              <Grid item xs={12} md={2}>
                <TextField
                  id="titular"
                  label="ID Titular"
                  fullWidth
                  variant="standard"
                  type="number"
                  value={titularId ? titularId : ""}
                  disabled={titularNombre !== null && titularNombre !== ""}
                  //onKeyDown={handleEnter}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setTitularId(e.target.value);
                    setTitularSeleccionado(null);
                    setRows(null);
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <TextField
                  id="razonSearch"
                  label="Apellido y Nombre o Razón Social"
                  fullWidth
                  variant="standard"
                  value={titularNombre ? titularNombre : ""}
                  disabled={
                    titularId !== null && titularId !== "" && titularId !== 0
                  }
                  //onKeyDown={handleEnter}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setTitularNombre(e.target.value);
                    setTitularSeleccionado(null);
                    setRows(null);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={1}>
                <Button
                  icon="pi pi-search"
                  className="p-button-rounded p-button-success"
                  onClick={() => buscarAfiliados()}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Autocomplete
                  id="razon"
                  fullWidth
                  options={rows}
                  disabled={!rows}
                  noOptionsText={"No hay opciones"}
                  style={{ marginLeft: ".8vw", color: "green" }}
                  //defaultValue={grupo ? agrupar[grupo - 1] : null}
                  value={titularSeleccionado}
                  disablePortal
                  getOptionLabel={(option) => option.nombre_razonsocial}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Seleccione el Afiliado"
                      variant="standard"
                    />
                  )}
                  onChange={(event, value) => {
                    if (value) {
                      setTitularSeleccionado(value);
                      setTitularId(value.id_titular);
                      setTitularNombre(null);
                    } else {
                      setTitularSeleccionado(null);
                    }
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6} align="center">
                <Tag value="Historias Clínicas Faltantes"></Tag>
              </Grid>
              <Grid item xs={12} md={6} align="center">
                <Tag value="Coseguros"></Tag>
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                style={{ paddingLeft: "1.5vw", marginTop: "0em" }}
              >
                <div className="p-field-checkbox">
                  <label htmlFor="hcServicio">H.C. Servicios</label>
                  <Checkbox
                    style={{ marginLeft: ".5vw" }}
                    inputId="hcServicio"
                    onChange={(e) => {
                      guardarFiltrosListadosServicios({
                        ...filtrosListadosServicios,
                        hcServicio: e.target.checked,
                      });
                      setVal(null);
                      setGrupo(null);
                    }}
                    checked={
                      filtrosListadosServicios
                        ? filtrosListadosServicios.hcServicio
                        : false
                    }
                    disabled={grupo && grupo !== null}
                  ></Checkbox>
                </div>
              </Grid>

              <Grid
                item
                xs={6}
                md={3}
                style={{ paddingLeft: 0, marginTop: "0em" }}
              >
                <div className="p-field-checkbox">
                  <label htmlFor="hcAdjunto">H.C. Adjuntos</label>
                  <Checkbox
                    style={{ marginLeft: ".5vw" }}
                    inputId="hcAdjunto"
                    onChange={(e) => {
                      guardarFiltrosListadosServicios({
                        ...filtrosListadosServicios,
                        hcAdjunto: e.target.checked,
                      });
                      setVal(null);
                      setGrupo(null);
                    }}
                    checked={
                      filtrosListadosServicios
                        ? filtrosListadosServicios.hcAdjunto
                        : false
                    }
                    disabled={grupo && grupo !== null}
                  ></Checkbox>
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                md={3}
                style={{ paddingLeft: 0, marginTop: "0em" }}
              >
                <div className="p-field-checkbox">
                  <label htmlFor="sinCoseguros">Sin Coseguros</label>
                  <Checkbox
                    style={{ marginLeft: ".5vw" }}
                    inputId="sinCoseguros"
                    onChange={(e) => {
                      guardarFiltrosListadosServicios({
                        ...filtrosListadosServicios,
                        sinCoseguros: e.target.checked,
                      });
                      setVal(null);
                      setGrupo(null);
                    }}
                    checked={
                      filtrosListadosServicios
                        ? filtrosListadosServicios.sinCoseguros
                        : false
                    }
                    disabled={grupo && grupo !== null}
                  ></Checkbox>
                </div>
              </Grid>

              <Grid
                item
                xs={6}
                md={3}
                style={{ paddingLeft: 0, marginTop: "0em" }}
              >
                <div className="p-field-checkbox">
                  <label htmlFor="conCoseguros">Con Coseguros</label>
                  <Checkbox
                    style={{ marginLeft: ".5vw" }}
                    inputId="conCoseguros"
                    onChange={(e) => {
                      guardarFiltrosListadosServicios({
                        ...filtrosListadosServicios,
                        conCoseguros: e.target.checked,
                      });
                      setVal(null);
                      setGrupo(null);
                    }}
                    checked={
                      filtrosListadosServicios
                        ? filtrosListadosServicios.conCoseguros
                        : false
                    }
                    disabled={grupo && grupo !== null}
                  ></Checkbox>
                </div>
              </Grid>

              <Grid
                item
                xs={6}
                md={3}
                style={{ paddingLeft: "1.5vw", marginTop: "0em" }}
              >
                <div className="p-field-checkbox">
                  <label htmlFor="auditados">Auditados</label>
                  <Checkbox
                    style={{ marginLeft: ".5vw" }}
                    inputId="auditados"
                    onChange={(e) => {
                      guardarFiltrosListadosServicios({
                        ...filtrosListadosServicios,
                        auditados: e.target.checked,
                      });
                      setVal(null);
                      setGrupo(null);
                    }}
                    checked={
                      filtrosListadosServicios
                        ? filtrosListadosServicios.auditados
                        : false
                    }
                    disabled={grupo && grupo !== null}
                  ></Checkbox>
                </div>
              </Grid>

              <Grid
                item
                xs={6}
                md={3}
                style={{ paddingLeft: 0, marginTop: "0em" }}
              >
                <div className="p-field-checkbox">
                  <label htmlFor="noAuditados">No Auditados</label>
                  <Checkbox
                    style={{ marginLeft: ".5vw" }}
                    inputId="noAuditados"
                    onChange={(e) => {
                      guardarFiltrosListadosServicios({
                        ...filtrosListadosServicios,
                        noAuditados: e.target.checked,
                      });
                      setVal(null);
                      setGrupo(null);
                    }}
                    checked={
                      filtrosListadosServicios
                        ? filtrosListadosServicios.noAuditados
                        : false
                    }
                    disabled={grupo && grupo !== null}
                  ></Checkbox>
                </div>
              </Grid>

              <Grid
                item
                xs={6}
                md={3}
                style={{ paddingLeft: "1.5vw", marginTop: "0em" }}
              >
                <div className="p-field-checkbox">
                  <label htmlFor="efectivo">Efectivo</label>
                  <Checkbox
                    style={{ marginLeft: ".5vw" }}
                    inputId="efectivo"
                    onChange={(e) => {
                      guardarFiltrosListadosServicios({
                        ...filtrosListadosServicios,
                        efectivo: e.target.checked,
                      });
                      setVal(null);
                      setGrupo(null);
                    }}
                    checked={
                      filtrosListadosServicios
                        ? filtrosListadosServicios.efectivo
                        : false
                    }
                    disabled={grupo && grupo !== null}
                  ></Checkbox>
                </div>
              </Grid>

              <Grid
                item
                xs={6}
                md={3}
                style={{ paddingLeft: 0, marginTop: "0em" }}
              >
                <div className="p-field-checkbox">
                  <label htmlFor="transferencias">Transferencias</label>
                  <Checkbox
                    style={{ marginLeft: ".5vw" }}
                    inputId="transferencias"
                    onChange={(e) => {
                      guardarFiltrosListadosServicios({
                        ...filtrosListadosServicios,
                        transferencia: e.target.checked,
                      });
                      setVal(null);
                      setGrupo(null);
                    }}
                    checked={
                      filtrosListadosServicios
                        ? filtrosListadosServicios.transferencia
                        : false
                    }
                    disabled={grupo && grupo !== null}
                  ></Checkbox>
                </div>
              </Grid>

              <Grid
                item
                xs={6}
                md={3}
                style={{ paddingLeft: "1.5vw", marginTop: "0em" }}
              >
                <div className="p-field-checkbox">
                  <label htmlFor="fueraZona">Fuera de Zona</label>
                  <Checkbox
                    style={{ marginLeft: ".5vw" }}
                    inputId="fueraZona"
                    onChange={(e) => {
                      guardarFiltrosListadosServicios({
                        ...filtrosListadosServicios,
                        fueraZona: e.target.checked,
                      });
                      setVal(null);
                      setGrupo(null);
                    }}
                    checked={
                      filtrosListadosServicios
                        ? filtrosListadosServicios.fueraZona
                        : false
                    }
                    disabled={grupo && grupo !== null}
                  ></Checkbox>
                </div>
              </Grid>
              <Grid
                item
                xs={6}
                md={9}
              />

              <Grid item xs={12} md={3}>
                <TextField
                  id="indicativo"
                  label={
                    configuracion && configuracion.destinatario !== 2
                      ? "Indicativo"
                      : "Id"
                  }
                  type="number"
                  fullWidth
                  variant="standard"
                  onKeyDown={handleEnter}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setIndicativo(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextField
                  id="dni"
                  label="D.N.I."
                  fullWidth
                  variant="standard"
                  type="number"
                  value={dni ? dni : ""}
                  onKeyDown={handleEnter}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => {
                    setDni(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="usuarios"
                  fullWidth
                  variant="standard"
                  options={usuarios}
                  noOptionsText={"No hay opciones"}
                  style={{ marginLeft: ".8vw" }}
                  //defaultValue={grupo ? agrupar[grupo - 1] : null}
                  //value={val}
                  disablePortal
                  getOptionLabel={(option) => option.usuario}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Usuarios"
                      variant="standard"
                    />
                  )}
                  onKeyDown={handleEnter}
                  onChange={(event, value) => {
                    if (value) {
                      setUsuario(value.usuario);
                    } else {
                      setUsuario(null);
                    }
                  }}
                />
              </Grid>

              <Grid
                  item
                  xs={12}
                  md={4}
                  style={{ paddingLeft: 0, marginTop: "-1vw" }}
                >
                  <Autocomplete
                    id="colores"
                    multiple
                    fullWidth
                    options={colores}
                    disableCloseOnSelect
                    noOptionsText={"No hay opciones"}
                    style={{ marginLeft: ".8vw" }}
                    disablePortal
                    getOptionLabel={(option) => option.nombre}
                    renderOption={(props, option, { selected }) => {
                      const { key, ...optionProps } = props;
                      return (
                        <li key={key} {...optionProps}>
                          <Checkbox2
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.nombre}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Colores"
                        variant="standard"
                      />
                    )}
                    //onKeyDown={handleEnter}
                    onChange={(event, value) => {
                      if (value) {
                        setColor(value);
                      } else {
                        setColor(null);
                      }
                    }}
                  />
                </Grid>


              <Grid
                item
                xs={6}
                md={6}
                style={{ paddingLeft: 0, marginTop: "0em" }}
              ></Grid>
            </Grid>
          </Dialog>
          <Dialog
            header={renderHeader}
            visible={visible}
            className="p-dialog-despacho"
            maximized={false}
            maximizable={false}
            icon="pi pi-external-link"
            closable={true}
            onHide={() => onHide()}
            //footer={renderFooter()}
            breakpoints={{ "960px": "75vw" }}
            style={{
              width: "100vw",
              padding: "0",
              overflowY: "visible",
            }}
            position="top-right"
          >
            <Grid container spacing={1} style={{ padding: "0" }}>
              <Grid item xs={12} md={8}>
                <ScrollPanel style={{ width: "100%", height: "100%" }}>
                  <div style={{ padding: "0em", lineHeight: "1.5" }}>
                    <ConsultaServicio />
                  </div>
                </ScrollPanel>
              </Grid>
              <Grid item xs={12} md={4}>
                <ScrollPanel
                  style={{ width: "100%", height: "100%" }}
                  className="custombar1"
                >
                  <div style={{ padding: "0em", lineHeight: "1.5" }}>
                    {notas &&
                    notas.length > 0 &&
                    selectedRow &&
                    notas[0].servicioId === selectedRow.id ? (
                      <NotasServicio />
                    ) : (
                      <Typography variant="h6" gutterBottom>
                        Cargando notas ...
                      </Typography>
                    )}
                  </div>
                </ScrollPanel>
              </Grid>
            </Grid>
          </Dialog>
          <Dialog
            header={renderHeaderUpload}
            visible={visibleUpload}
            className="p-dialog-despacho"
            icon="pi pi-external-link"
            closable={true}
            onHide={() => onHideUpload()}
            //footer={renderFooter()}
            breakpoints={{ "960px": "75vw" }}
            style={{
              width: "55vw",
              padding: "0",
              overflowY: "visible",
            }}
            position="center-left"
          >
            <Grid
              container
              spacing={3}
              style={{ padding: "0", marginTop: "1vw" }}
            >
              <Grid item xs={12} md={12}>
                <div className="file-select" id="src-file1">
                  <input
                    type="file"
                    name="src-file1"
                    aria-label="Archivo"
                    accept="image/*,.pdf"
                    onChange={onChange}
                  />
                </div>

                <div style={{ marginLeft: "1vw", display: "inline-block" }}>
                  <label htmlFor="src-file1">{filename}</label>
                </div>
              </Grid>
              <Grid item xs={12} md={12}>
                <Button
                  type="button"
                  icon="pi pi-upload"
                  onClick={uploadFile}
                  className="p-button-warning p-mr-2"
                  style={{ marginRight: "1em" }}
                  label="Upload"
                />
              </Grid>
            </Grid>
          </Dialog>
          <Dialog
            header={renderHeaderModificar()}
            visible={visibleModificaciones}
            closable={false}
            footer={renderFooterModificar()}
            breakpoints={{ "960px": "95vw" }}
            style={{ width: "95vw", paddingBottom: "0" }}
            position="center"
          >
            <Grid container spacing={1} style={{ padding: "2vw" }}>
              {servicioModificado &&
              motivosConsultas &&
              motivosConsultas.length > 0 &&
              diagnosticos ? (
                <Fragment>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="titularId"
                      label="Titular Afiliado"
                      value={
                        servicioModificado ? servicioModificado.titularId : null
                      }
                      required
                      fullWidth
                      variant="standard"
                      onBlur={(e) => handleTitularBlur(e)}
                      size="small"
                      //className={classes.textField}
                      //disabled={afiliadoCartera ? true : false}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarServicioModificado({
                          ...servicioModificado,
                          titularId: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="nombreTitular"
                      label="Nombre Titular"
                      value={
                        servicioModificado
                          ? servicioModificado.nombreTitular
                          : null
                      }
                      required
                      disabled
                      InputLabelProps={{
                        shrink: true,
                      }}
                      fullWidth
                      variant="standard"
                      size="small"
                      //className={classes.textField}
                      //disabled={afiliadoCartera ? true : false}
                      onKeyDown={handleEnter}
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <NumericFormat
                      {...props}
                      id="dni"
                      label="D.N.I."
                      customInput={TextField}
                      format="########"
                      type="numeric"
                      value={
                        servicioModificado
                          ? servicioModificado.numeroDocumento
                          : null
                      }
                      required
                      fullWidth
                      variant="standard"
                      size="small"
                      //className={classes.textField}
                      //disabled={afiliadoCartera ? true : false}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarServicioModificado({
                          ...servicioModificado,
                          numeroDocumento: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      id="nombre"
                      label="Apellido y Nombre"
                      value={
                        servicioModificado ? servicioModificado.nombre : null
                      }
                      required
                      fullWidth
                      variant="standard"
                      size="small"
                      //className={classes.textField}
                      //disabled={afiliadoCartera ? true : false}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarServicioModificado({
                          ...servicioModificado,
                          nombre: e.target.value.toUpperCase(),
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <NumericFormat
                      {...props}
                      id="telefono"
                      label="Teléfono"
                      customInput={TextField}
                      format="####################"
                      type="numeric"
                      value={
                        servicioModificado ? servicioModificado.telefono : null
                      }
                      required
                      fullWidth
                      variant="standard"
                      size="small"
                      //className={classes.textField}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarServicioModificado({
                          ...servicioModificado,
                          telefono: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <TextField
                      id="provincia"
                      label="Provincia"
                      value={
                        servicioModificado ? servicioModificado.provincia : null
                      }
                      required
                      fullWidth
                      variant="standard"
                      size="small"
                      //className={classes.textField}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarServicioModificado({
                          ...servicioModificado,
                          provincia: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={5}>
                    <TextField
                      id="domicilio"
                      label="Dirección"
                      value={
                        servicioModificado ? servicioModificado.domicilio : null
                      }
                      fullWidth
                      variant="standard"
                      size="small"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      //className={classes.textField}
                      onKeyDown={handleEnter}
                      onBlur={(e) => handleInputBlur(e)}
                      onChange={(e) =>
                        guardarServicioModificado({
                          ...servicioModificado,
                          domicilio: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      id="domicilioGeo"
                      fullWidth
                      noOptionsText={"No hay opciones"}
                      options={direcciones}
                      getOptionLabel={(option) => option.label}
                      openOnFocus={true}
                      disablePortal
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Dirección Geolocalizada"
                          variant="standard"
                        />
                      )}
                      onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarServicioModificado({
                            ...servicioModificado,
                            domicilioGeo: value.label,
                            localidadGeo: value.localidad.toUpperCase(),
                            localidad:
                              value.localidad !== ""
                                ? value.localidad.toUpperCase() +
                                  "-" +
                                  value.provincia.toUpperCase()
                                : value.provincia.toUpperCase(),
                            lat: value.lat,
                            lon: value.lon,
                          });
                        } else {
                          guardarServicioModificado({
                            ...servicioModificado,
                            domicilioGeo: null,
                            localidadGeo: null,
                            lat: null,
                            lon: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={4} md={1}>
                    <IconButton
                      type="submit"
                      //className={classes.iconButton}
                      aria-label="search"
                      onClick={(e) => handleClickOpen()}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Grid>

                  <Grid item xs={12} md={5}>
                    <TextField
                      id="entre"
                      label="Entre calles"
                      fullWidth
                      variant="standard"
                      size="small"
                      //className={classes.textField}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarServicioModificado({
                          ...servicioModificado,
                          entreCalles: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      id="localidad"
                      label="Localidad"
                      value={
                        servicioModificado ? servicioModificado.localidad : null
                      }
                      fullWidth
                      variant="standard"
                      size="small"
                      //className={classes.textField}
                      //disabled={afiliadoCartera ? true : false}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarServicioModificado({
                          ...servicioModificado,
                          localidad: e.target.value.toUpperCase(),
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={1}>
                    <NumericFormat
                      {...props}
                      id="edad"
                      label="Edad"
                      customInput={TextField}
                      format="###"
                      type="numeric"
                      value={
                        servicioModificado ? servicioModificado.edad : null
                      }
                      fullWidth
                      variant="standard"
                      size="small"
                      required
                      //className={classes.textField}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarServicioModificado({
                          ...servicioModificado,
                          edad: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={9}>
                    <Autocomplete
                      id="motivoConsulta"
                      fullWidth
                      required
                      disablePortal
                      debug
                      noOptionsText={"No hay opciones"}
                      options={opcionesMotivos}
                      //classes={classes }
                      defaultValue={
                        motivosConsultas &&
                        servicioModificado &&
                        servicioModificado.motivoConsultaId !== undefined
                          ? motivosConsultas[
                              motivosConsultas.findIndex(
                                (item) =>
                                  item.id ===
                                  servicioModificado.motivoConsultaId
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.nombre}
                      renderOption={(props, option) => {
                        const { nombre, codColor } = option;
                        return (
                          <li
                            {...props}
                            style={{
                              backgroundColor: codColor,
                              color:
                                codColor === "#FFFF00" ||
                                codColor === "#FFFFFF" ||
                                codColor === "#00FFFF"
                                  ? "#000000"
                                  : "#FFFFFF",
                            }}
                          >
                            {nombre}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Motivo de consulta"
                          variant="standard"
                        />
                      )}
                      onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          //console.log(value);
                          guardarServicioModificado({
                            ...servicioModificado,
                            motivoConsultaId: value.id,
                            motivoConsultaNombre: value.nombre,
                            motivoConsultaCodigoColor: value.codColor,
                            motivoConsultaColor: value.color,
                          });
                          //guardarCoseguroServicio(null);
                          //displayCoseguro(value.id);
                        } else {
                          guardarServicioModificado({
                            ...servicioModificado,
                            motivoConsultaId: null,
                            motivoConsultaNombre: null,
                            motivoConsultaCodigoColor: null,
                            motivoConsultaColor: null,
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={1} md={1}>
                    <Button
                      size="normal"
                      variant="outlined"
                      style={{
                        marginTop: "0.5em",
                        backgroundColor:
                          servicioModificado.motivoConsultaCodigoColor
                            ? servicioModificado.motivoConsultaCodigoColor
                            : "#FFF",
                        color:
                          servicioModificado.motivoConsultaColor ===
                            "AMARILLO" ||
                          servicioModificado.motivoConsultaColor ===
                            "CELESTE" ||
                          servicioModificado.motivoConsultaColor === "BLANCO"
                            ? "#000000"
                            : "#FFFFFF",
                      }}
                    >
                      {servicioModificado &&
                      servicioModificado.motivoConsultaColor !== null
                        ? servicioModificado.motivoConsultaColor
                        : "Color"}
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="operadorEmisor"
                      label="Operador emisor"
                      fullWidth
                      variant="standard"
                      size="small"
                      value={
                        servicioModificado
                          ? servicioModificado.operadorEmisor
                          : null
                      }
                      //className={classes.textField}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarServicioModificado({
                          ...servicioModificado,
                          operadorEmisor: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="indicativoEmisor"
                      label="Indicativo emisor"
                      fullWidth
                      variant="standard"
                      size="small"
                      value={
                        servicioModificado
                          ? servicioModificado.indicativoEmisor
                          : null
                      }
                      //className={classes.textField}
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarServicioModificado({
                          ...servicioModificado,
                          indicativoEmisor: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                      id="obraSocial"
                      label="Obra social/Convenio"
                      fullWidth
                      variant="standard"
                      size="small"
                      value={
                        servicioModificado
                          ? servicioModificado.obraSocial
                          : null
                      }
                      //className={classes.textField}
                      InputLabelProps={{ style: { fontSize: 14 } }} // font size of input label
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarServicioModificado({
                          ...servicioModificado,
                          obraSocial: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={6} md={3}>
                    <TextField
                      id="nroObraSocial"
                      label="Nro. Obra social/Convenio"
                      fullWidth
                      variant="standard"
                      size="small"
                      value={
                        servicioModificado
                          ? servicioModificado.nroObraSocial
                          : null
                      }
                      //className={classes.textField}
                      InputLabelProps={{ style: { fontSize: 12 } }} // font size of input label
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarServicioModificado({
                          ...servicioModificado,
                          nroObraSocial: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      id="diagnostico"
                      fullWidth
                      disablePortal
                      required
                      noOptionsText={"No hay opciones"}
                      options={diagnosticos}
                      defaultValue={
                        servicioModificado &&
                        servicioModificado.diagnosticoId !== undefined
                          ? diagnosticos[
                              diagnosticos.findIndex(
                                (item) =>
                                  item.id === servicioModificado.diagnosticoId
                              )
                            ]
                          : null
                      }
                      getOptionLabel={(option) => option.nombre}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Diagnóstico"
                          variant="standard"
                        />
                      )}
                      onKeyDown={handleEnter}
                      onChange={(event, value) => {
                        if (value) {
                          guardarServicioModificado({
                            ...servicioModificado,
                            diagnosticoId: value.id,
                            diagnosticoNombre: value.nombre,
                          });
                        } else {
                          guardarServicioModificado({
                            ...servicioModificado,
                            diagnosticoId: null,
                            diagnosticoNombre: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextareaAutosize
                      id="detalleDiagnostico"
                      maxRows={8}
                      minRows={5}
                      aria-label="maximum height"
                      placeholder="Detalle diagnóstico"
                      defaultValue={servicioModificado.detalleDiagnostico}
                      style={{
                        width: "-webkit-fill-available",
                        marginTop: ".5em",
                      }}
                      onChange={(event) => {
                        guardarServicioModificado({
                          ...servicioModificado,
                          detalleDiagnostico: event.target.value,
                        });
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} md={1.5}>
                    <TextField
                      id="coseguroAbonar"
                      label="Coseguro Abonar"
                      fullWidth
                      variant="standard"
                      size="small"
                      //className={classes.textField}
                      onKeyDown={handleEnter}
                      value={
                        servicioModificado
                          ? servicioModificado.coseguroAbonar
                          : null
                      }
                      onChange={(e) =>
                        guardarServicioModificado({
                          ...servicioModificado,
                          coseguroAbonar: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={6} md={1.5}>
                    <TextField
                      id="coseguroAbonado"
                      label="Coseguro Abonado"
                      fullWidth
                      variant="standard"
                      size="small"
                      //className={classes.textField}
                      onKeyDown={handleEnter}
                      value={
                        servicioModificado
                          ? servicioModificado.coseguroAbonado
                          : null
                      }
                      onChange={(e) => {
                        if (Number(e.target.value) > 0) {
                          guardarServicioModificado({
                            ...servicioModificado,
                            coseguroAbonado: e.target.value,
                            formaPago: "efectivo",
                          });
                        } else {
                          guardarServicioModificado({
                            ...servicioModificado,
                            coseguroAbonado: e.target.value,
                            formaPago: null,
                          });
                        }
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={1} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          id="covid"
                          color="primary"
                          checked={
                            servicioModificado
                              ? servicioModificado.covid
                              : false
                          }
                          onKeyDown={handleEnter}
                          onChange={(e) =>
                            guardarServicioModificado({
                              ...servicioModificado,
                              covid: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Covid"
                      labelPlacement="start"
                    />
                  </Grid>

                  <Grid item xs={12} md={1.5} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          id="fueraZona"
                          color="primary"
                          checked={
                            servicioModificado
                              ? servicioModificado.fueraZona
                              : false
                          }
                          onKeyDown={handleEnter}
                          onChange={(e) =>
                            guardarServicioModificado({
                              ...servicioModificado,
                              fueraZona: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Fuera de Zona"
                      labelPlacement="start"
                    />
                  </Grid>

                  <Grid item xs={12} md={1.5} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          id="videollamada"
                          color="primary"
                          checked={
                            servicioModificado
                              ? servicioModificado.videollamada
                              : false
                          }
                          onKeyDown={handleEnter}
                          onChange={(e) =>
                            guardarServicioModificado({
                              ...servicioModificado,
                              videollamada: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Videollamada"
                      labelPlacement="start"
                    />
                  </Grid>

                  <Grid item xs={12} md={2}>
                    <TextField
                      id="celular"
                      label="WhatsApp Videollamada"
                      value={
                        servicioModificado ? servicioModificado.celular : null
                      }
                      required
                      fullWidth
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarServicioModificado({
                          ...servicioModificado,
                          celular: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={1.5} style={{ paddingLeft: 0 }}>
                    <FormControlLabel
                      value="yes"
                      control={
                        <Checkbox
                          id="rederivado"
                          color="primary"
                          checked={
                            servicioModificado
                              ? servicioModificado.rederivado
                              : false
                          }
                          onKeyDown={handleEnter}
                          onChange={(e) =>
                            guardarServicioModificado({
                              ...servicioModificado,
                              rederivado: e.target.checked,
                            })
                          }
                        />
                      }
                      label="Rederivado"
                      labelPlacement="start"
                    />
                  </Grid>

                  <Grid item xs={12} md={1.5}>
                    <TextField
                      id="indicativoRederivado"
                      label="Ind. Rederivado"
                      value={
                        servicioModificado
                          ? servicioModificado.indicativoRederivado
                          : null
                      }
                      fullWidth
                      variant="standard"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      size="small"
                      onKeyDown={handleEnter}
                      onChange={(e) =>
                        guardarServicioModificado({
                          ...servicioModificado,
                          indicativoRederivado: e.target.value,
                        })
                      }
                    />
                  </Grid>


                  <Grid item xs={6} md={2} >
                  <TextField
                    InputProps={
                      servicioModificado.color === "AZUL"
                        ? {
                            style: {
                              fontSize: "small",
                              backgroundColor: "blue",
                              color: "white",
                            },
                          }
                        : servicioModificado.color === "NARANJA"
                        ? {
                            style: {
                              fontSize: "small",
                              backgroundColor: "orange",
                              color: "black",
                            },
                          }
                        : servicioModificado.color === "VERDE"
                        ? {
                            style: {
                              fontSize: "small",
                              backgroundColor: "green",
                              color: "white",
                            },
                          }
                        : servicioModificado.color === "CELESTE"
                        ? {
                            style: {
                              fontSize: "small",
                              backgroundColor: "aqua",
                              color: "black",
                            },
                          }
                        : servicioModificado.color === "AMARILLO"
                        ? {
                            style: {
                              fontSize: "small",
                              backgroundColor: "yellow",
                              color: "black",
                            },
                          }
                        : servicioModificado.color === "ROJO"
                        ? {
                            style: {
                              fontSize: "small",
                              backgroundColor: "red",
                              color: "white",
                            },
                          }
                        : servicioModificado.color === "BLANCO"
                        ? {
                            style: {
                              fontSize: "small",
                              backgroundColor: "white",
                              color: "black",
                            },
                          }
                        : servicioModificado.color === "NEGRO"
                        ? {
                            style: {
                              fontSize: "small",
                              backgroundColor: "black",
                              color: "white",
                            },
                          }
                        : servicioModificado.color === "FUCCIA"
                        ? {
                            style: {
                              fontSize: "small",
                              backgroundColor: "fucsia",
                              color: "black",
                            },
                          }
                        : null
                    }
                    InputLabelProps={{
                      style: { color: "#2196F3", fontWeight: "bold" },
                    }}
                    id="color"
                    label="Color Final"
                    value={servicioModificado ? servicioModificado.color : null}
                    fullWidth
                    size="small"
                    variant="standard"
                    sx={{
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: "black",
                      },
                    }}
                    disabled={true}
                  />
                </Grid>


                <Grid item xs={4} md={1}>
                    <IconButton
                      type="submit"
                      //className={classes.iconButton}
                      aria-label="search"
                      onClick={(e) => handleClickUp()}
                    >
                      <ArrowUpwardIcon />
                    </IconButton>
                  </Grid>






                  {configuracion.destinatario === 4 &&
                  servicioModificado &&
                  servicioModificado.coseguroAbonado !== undefined &&
                  servicioModificado.coseguroAbonado &&
                  Number(servicioModificado.coseguroAbonado) > 0 ? (
                    <Fragment>
                      <label
                        style={{ marginLeft: "0vw", marginTop: "1.5vw" }}
                        htmlFor="formaPago"
                      >
                        Forma de Pago
                      </label>

                      <Grid item xs={6} md={2}>
                        <label
                          style={{ marginLeft: "1vw", marginTop: "1vw" }}
                          htmlFor="efectivo"
                        >
                          Efectivo
                        </label>
                        <RadioButton
                          inputId="pagoEfectivo"
                          value="efectivo"
                          name="efectivo"
                          onChange={(e) =>
                            guardarServicioModificado({
                              ...servicioModificado,
                              formaPago: e.target.value,
                            })
                          }
                          checked={
                            servicioModificado &&
                            servicioModificado.formaPago === "efectivo"
                          }
                          style={{ marginTop: "1.2vw", marginLeft: "0.5vw" }}
                        />
                      </Grid>

                      <Grid item xs={6} md={2}>
                        <label
                          style={{ marginLeft: "0vw", marginTop: "1vw" }}
                          htmlFor="efectivo"
                        >
                          Transferencia
                        </label>
                        <RadioButton
                          inputId="pagoTransferencia"
                          value="transferencia"
                          name="transferencia"
                          onChange={(e) =>
                            guardarServicioModificado({
                              ...servicioModificado,
                              formaPago: e.target.value,
                            })
                          }
                          checked={
                            servicioModificado &&
                            servicioModificado.formaPago === "transferencia"
                          }
                          style={{ marginTop: "1.2vw", marginLeft: "0.5vw" }}
                        />
                      </Grid>
                      <Grid item xs={6} md={3}/>

                    </Fragment>
                  ) : null}

                  {configuracion.destinatario === 4 && servicioModificado ? (
                    <Fragment>
                      <Grid item xs={12} md={1.8}>
                        <TextField
                          id="horaTomado"
                          label="Tomado"
                          value={
                            servicioModificado && servicioModificado.tomado
                              ? servicioModificado.tomado
                              : null
                          }
                          type="datetime-local"
                          fullWidth
                          variant="standard"
                          //onKeyDown={handleEnter}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            guardarServicioModificado({
                              ...servicioModificado,
                              tomado: e.target.value,
                            });
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={1.8}>
                        <TextField
                          id="horaPasado"
                          label="Pasado"
                          value={
                            servicioModificado && servicioModificado.pasado
                              ? servicioModificado.pasado
                              : null
                          }
                          type="datetime-local"
                          fullWidth
                          variant="standard"
                          //onKeyDown={handleEnter}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            guardarServicioModificado({
                              ...servicioModificado,
                              pasado: e.target.value,
                            });
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={1.8}>
                        <TextField
                          id="horaSalida"
                          label="Salida"
                          value={
                            servicioModificado && servicioModificado.salida
                              ? servicioModificado.salida
                              : null
                          }
                          type="datetime-local"
                          fullWidth
                          variant="standard"
                          //onKeyDown={handleEnter}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            guardarServicioModificado({
                              ...servicioModificado,
                              salida: e.target.value,
                            });
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={1.8}>
                        <TextField
                          id="horaLlegada"
                          label="Llegada"
                          value={
                            servicioModificado && servicioModificado.llegada
                              ? servicioModificado.llegada
                              : null
                          }
                          type="datetime-local"
                          fullWidth
                          variant="standard"
                          //onKeyDown={handleEnter}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            guardarServicioModificado({
                              ...servicioModificado,
                              llegada: e.target.value,
                            });
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} md={1.8}>
                        <TextField
                          id="horaFin"
                          label="Fin"
                          value={
                            servicioModificado && servicioModificado.fin
                              ? servicioModificado.fin
                              : null
                          }
                          type="datetime-local"
                          fullWidth
                          variant="standard"
                          //onKeyDown={handleEnter}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => {
                            guardarServicioModificado({
                              ...servicioModificado,
                              fin: e.target.value,
                            });
                          }}
                        />
                      </Grid>
                    </Fragment>
                  ) : null}
                </Fragment>
              ) : null}
            </Grid>
            <br />
          </Dialog>
        </Fragment>
      ) : null}
      <Grid item xs={12} sm={12}></Grid>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
