import React, { Fragment, useContext, useEffect, useState } from "react";
import $ from "jquery";
//import "../../assets/css/demo.css";
//import "./dist/hc-offcanvas-nav.carbon.css";
import "../../assets/css/hc-offcanvas-nav.css";
import hcOffcanvasNav from "hc-offcanvas-nav";
//import hcOffcanvasNav from "../../dist/hc-offcanvas-nav";
import { AuthContext } from "../../context/AuthContext";
import { ConfiguracionContext } from "../../context/ConfiguracionContext";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import AirportShuttleOutlinedIcon from "@mui/icons-material/AirportShuttleOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PhoneInTalkOutlinedIcon from "@mui/icons-material/PhoneInTalkOutlined";
import AlarmIcon from "@mui/icons-material/Alarm";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import FlagIcon from "@mui/icons-material/Flag";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BuildIcon from "@mui/icons-material/Build";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import Spinner from "./Spinner";
import NumbersIcon from "@mui/icons-material/Numbers";

function Menu() {
  const authContext = useContext(AuthContext);
  const { autenticado, usuario, menu, usuarioAutenticado, guardarMenu } =
    authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const { configuracion, obtenerConfiguracion } = configuracionContext;

  const [band, setBand] = useState(true);

  useEffect(() => {
    // Menu Trigger

    usuarioAutenticado();

    $(document).ready(function ($) {
      var Nav = new hcOffcanvasNav("#main-nav", {
        disableAt: false,
        customToggle: ".toggle",
        levelSpacing: 40,
        navTitle: "Home",
        levelTitles: true,
        levelTitleAsBack: true,
        pushContent: "#container",
        labelClose: false,
        position: "left",
        levelOpen: "overlap",
      });

      // add new items to original nav
      $("#main-nav")
        .find("li.add")
        .children("a")
        .on("click", function () {
          var $this = $(this);
          var $li = $this.parent();
          var items = eval("(" + $this.attr("data-add") + ")");
          $li.before('<li class="new"><a href="#">' + items[0] + "</a></li>");
          items.shift();

          if (!items.length) {
            $li.remove();
          } else {
            $this.attr("data-add", JSON.stringify(items));
          }

          Nav.update(true); // update DOM
        });

      //Nav.open(2,2);
    });
  }, []);

  useEffect(() => {
    obtenerConfiguracion();
  }, []);

  return (
    <Fragment>
      {band ? (
        <div id="container">
          <div className="wrapper cf">
            <nav id="main-nav">
              <ul className="first-nav">
                <li>
                  <span>
                    <AirportShuttleOutlinedIcon
                      style={{ fontSize: "medium" }}
                    />{" "}
                    Servicios
                  </span>
                  <ul>
                    <li>
                      <a href="/tomaservicio">
                        <PhoneInTalkOutlinedIcon
                          style={{ fontSize: "medium" }}
                        />{" "}
                        Toma de Servicios
                      </a>
                    </li>
                    <li>
                      <a href="/despachos">
                        <AirportShuttleOutlinedIcon
                          style={{ fontSize: "medium" }}
                        />{" "}
                        Despachos
                      </a>
                    </li>
                    <li>
                      <a href="/tripulaciones">
                        <SupervisorAccountOutlinedIcon
                          style={{ fontSize: "medium" }}
                        />{" "}
                        Tripulaciones
                      </a>
                    </li>
                    <li>
                      <a href="#">Servicios Programados</a>
                      <div className="subnav-container">
                        <ul>
                          <li>
                            <a href="/traslados">Traslados</a>
                          </li>
                          <li>
                            <a href="/eventos">Eventos</a>
                          </li>
                          <li>
                            <a href="/pendienteseliminados">Eliminados</a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <a href="/serviciospendientes">
                        <AccessTimeIcon style={{ fontSize: "medium" }} />{" "}
                        Pendientes
                      </a>
                    </li>
                    <li>
                      <a href="/serviciosfinalizados">
                        <FlagIcon style={{ fontSize: "medium" }} /> Finalizados
                      </a>
                    </li>
                    <li>
                      <a href="/serviciosanulados">
                        <DeleteIcon style={{ fontSize: "medium" }} /> Anulados
                      </a>
                    </li>
                    <li>
                      <a href="#">Valorización de Servicios</a>
                      <div className="subnav-container">
                        <ul>
                          <li>
                            <a href="/valorizarservicios">Valores Servicios</a>
                          </li>
                          <li>
                            <a href="/feriados">Feriados</a>
                          </li>
                        </ul>
                      </div>
                    </li>
                    <li>
                      <a href="/tripulacionesprogramacion">
                        <AccessTimeIcon style={{ fontSize: "medium" }} />{" "}
                        Programación de Tripulaciones
                      </a>
                    </li>
                  </ul>
                </li>
                <li>
                  <span>
                    <SettingsOutlinedIcon style={{ fontSize: "medium" }} />{" "}
                    Configuración
                  </span>
                  <ul>
                    <li>
                      <a href="/numerointerno">
                        <PhoneOutlinedIcon style={{ fontSize: "medium" }} />{" "}
                        Número de Interno
                      </a>
                    </li>
                    <li>
                      <a href="/account">
                        <GroupAddIcon style={{ fontSize: "medium" }} /> Crear
                        Usuarios
                      </a>
                    </li>
                    <li>
                      <a href="/accesos">
                        <GroupOutlinedIcon style={{ fontSize: "medium" }} />{" "}
                        Accesos a Usuarios
                      </a>
                    </li>
                    <li>
                      <a href="/parametros">
                        <TuneOutlinedIcon style={{ fontSize: "medium" }} />{" "}
                        Parámetros del Servicio
                      </a>
                    </li>
                    <li>
                      <span>
                        <ArchiveOutlinedIcon style={{ fontSize: "medium" }} />{" "}
                        Archivos
                      </span>

                      <div className="subnav-container">
                        <ul>
                          <li>
                            <a href="/antecedentes">Antecedentes</a>
                          </li>
                          <li>
                            <a href="/tratamientos">Tratamientos</a>
                          </li>
                          <li>
                            <a href="/tiposservicios">Tipos de servicios</a>
                          </li>
                          <li>
                            <a href="/motivosconsultas">Motivos de Consulta</a>
                          </li>
                          <li>
                            <a href="/diagnosticos">Diagnósticos</a>
                          </li>
                          <li>
                            <a href="/medicos">Médicos</a>
                          </li>
                          <li>
                            <a href="/choferes">Choferes</a>
                          </li>
                          <li>
                            <a href="/enfermeros">Enfermeros</a>
                          </li>
                          <li>
                            <a href="/tiposmoviles">Tipos de Móviles</a>
                          </li>
                          <li>
                            <a href="/bases">Bases Operativas</a>
                          </li>

                          <li>
                            <a href="#">Móviles</a>
                            <div className="subnav-container">
                              <ul>
                                <li>
                                  <a href="/movilespropios">Propios</a>
                                </li>
                                <li>
                                  <a href="/movilesderivados">Derivados</a>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li>
                            <a href="/telefonos">Teléfonos</a>
                          </li>
                          <li>
                            <a href="/colores">Colores</a>
                          </li>
                          <li>
                            <a href="/coseguros">Coseguros</a>
                          </li>
                          <li>
                            <a href="#">Medicamentos</a>
                            <div className="subnav-container">
                              <ul>
                                <li>
                                  <a href="/almacenes">Almacenes</a>
                                </li>
                                {/*
                                <li>
                                  <a href="/almacenesmoviles">
                                    Almacenes de Móviles
                                  </a>
                                </li>
                                */}
                                <li>
                                  <a href="/medicamentos">Medicamentos</a>
                                </li>
                                <li>
                                  <a href="/movimientosmedicamentos">
                                    Movimientos
                                  </a>
                                </li>
                                <li>
                                  <a href="/stocksmedicamentos">Stocks</a>
                                </li>
                                <li>
                                  <a href="/ajustesstocksmedicamentos">
                                    Ajustes de Stock
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li>
                            <a href="/topes">Topes de Servicios</a>
                          </li>
                          {configuracion && configuracion.cargaConvenios ? (
                            <li>
                              <a href="/cargaconvenios">Carga de Convenios</a>
                            </li>
                          ) : null}

                          {configuracion && configuracion.institucionId ? (
                            <li>
                              <a href="#">Recetas</a>
                              <div className="subnav-container">
                                <ul>
                                  <li>
                                    <a href="/registromedicos">Médicos</a>
                                  </li>
                                  <li>
                                    <a href="/recetas">Recetas</a>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          ) : null}
                        </ul>
                      </div>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">
                    {" "}
                    <FormatIndentIncreaseIcon
                      style={{ fontSize: "medium" }}
                    />{" "}
                    Listados y Estadísticas
                  </a>
                  <div className="subnav-container">
                    <ul>
                      <li>
                        <a href="/listadosservicios">Servicios</a>
                      </li>
                      <li>
                        <a href="/listadosdemoras">Demoras</a>
                      </li>
                      <li>
                        <a href="/listadoslaborales">Laborales</a>
                      </li>
                      <li>
                        <a href="/listadostraslados">Traslados</a>
                      </li>
                      <li>
                        <a href="/listadostopes">Topes Superados</a>
                      </li>
                      <li>
                        <a href="/listadoshorariostripulacion">
                          Horarios Tripulación
                        </a>
                      </li>
                      <li>
                        <a href="#">Liquidaciones</a>

                        <div className="subnav-container">
                          <ul>
                            <li>
                              <a href="/listadosliquidacionmedicos">
                                Liquidación Médicos
                              </a>
                            </li>
                            <li>
                              <a href="/listadosliquidacionchoferes">
                                Liquidación Choferes
                              </a>
                            </li>
                            <li>
                              <a href="/listadosliquidacionenfermeros">
                                Liquidación Enfermeros
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <a href="/listadosprogramaciontripulaciones">
                          Programación de Tripulaciones
                        </a>
                      </li>
                      <li>
                        <a href="#">Estadísticas</a>
                        <div className="subnav-container">
                          <ul>
                            <li>
                              <a href="/estadisticaserviciosmesames">
                                Servicios Mes a Mes
                              </a>
                            </li>
                            <li>
                              <a href="/estadisticaserviciossemanales">
                                Servicios Semanales
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li>
                        <a href="#">Exportar</a>

                        <div className="subnav-container">
                          <ul>
                            <li>
                              <a href="/procesospami">PAMI</a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <span>
                    <BuildIcon style={{ fontSize: "medium" }} /> Utiles
                  </span>
                  <ul>
                    <li>
                      <a href="/tickets">
                        <ConfirmationNumberIcon
                          style={{ fontSize: "medium" }}
                        />{" "}
                        Tickets
                      </a>
                    </li>
                    <li>
                      <a href="/version">
                        <NumbersIcon style={{ fontSize: "medium" }} /> Versión
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
          <a className="toggle" href="#" style={{ marginLeft: "1.2em" }}>
            {/*<span />*/}
          </a>
        </div>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
}

export default Menu;
