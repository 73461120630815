import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Autocomplete from "@mui/material/Autocomplete";
import { AlertContext } from "../../../../context/AlertContext";
import { ConfiguracionContext } from "../../../../context/ConfiguracionContext";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Redirect } from "react-router-dom";
import Spinner from "../../Spinner";
import { AuthContext } from "../../../../context/AuthContext";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "../../../../assets/css/DataTableDemo.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import ButtonMui from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import imageToBase64 from "image-to-base64/browser";
import hcOffcanvasNav from "hc-offcanvas-nav";
import $ from "jquery";
import { saveAs } from "file-saver";
import { Navigate, useNavigate } from "react-router-dom";
import Barra from "../../Barra";
import { Dropdown } from "primereact/dropdown";
import SignatureCanvas from "react-signature-canvas";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import axios from "axios";

export default function RegistroMedicos(props) {
  //const classes = useStyles();
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  let navigate = useNavigate();

  const exportColumns = [
    { title: "ID", dataKey: "id" },
    { title: "Apellido y Nombre", dataKey: "nombre" },
    { title: "Domicilio", dataKey: "domicilio" },
    { title: "Teléfono", dataKey: "telefono" },
    { title: "Email", dataKey: "email" },
    { title: "Mat. Nacional", dataKey: "mn" },
    { title: "Mat. Provincial", dataKey: "mp" },
    { title: "D.N.I.", dataKey: "numeroDocumento" },
    { title: "C.U.I.T./C.U.I.L.", dataKey: "cuit" },
    { title: "Habilitado", dataKey: "habilitado" },
  ];

  const titulos = [
    { name: "Dr", dataKey: "1" },
    { name: "Dra", dataKey: "2" },
  ];

  const [medico, guardarMedico] = useState(null);
  const [medicoElegido, setMedicoElegido] = useState(null);
  const [provinciaElegida, setProvinciaElegida] = useState(null);
  const [tituloElegido, setTituloElegido] = useState(null);
  const [visible, setVisible] = useState(false);
  const toast = useRef(null);
  const toastWarm = useRef(null);
  const [postImage, setPostImage] = useState({
    myFile: "",
  });
  const [visibleUpload, setVisibleUpload] = useState(false);
  const [file, setFile] = useState(null);
  const [filename, setFilename] = useState(null);
  const [medicoUpload, setMedicoUpload] = useState(null);
  const sigRef = useRef();
  const [signature, setSignature] = useState(null);

  //Context

  const alertContext = useContext(AlertContext);
  const { mensajeAlerta } = alertContext;

  const authContext = useContext(AuthContext);
  const { state, usuarioAutenticado } = authContext;

  const configuracionContext = useContext(ConfiguracionContext);
  const {
    statusAcceso,
    statusAcceso2,
    configuracion,
    medicos,
    provincias,
    obtenerMedicos,
    registroMedico,
    obtenerProvincias,
    obtenerConfiguracion,
  } = configuracionContext;

  useEffect(() => {
    obtenerMedicos({ todos: true });
    obtenerProvincias({ todos: true });
    obtenerConfiguracion();
  }, [state.id]);

  setTimeout(() => {
    if (statusAcceso === 400) {
      accesoDenegado();
    }
  }, 3000);

  const editClick = (e) => {
    if (selectedRow) {
      guardarMedico(selectedRow);
      setVisible(true);
    } else {
      toast.current.show({
        severity: "info",
        summary: "Editar",
        detail: "Debe seleccionar un médico.",
        life: 3000,
      });
    }
  };

  const exitClick = (e) => {
    navigate(`/`);
    var Nav = new hcOffcanvasNav("#main-nav", {
      disableAt: false,
      customToggle: ".toggle",
      levelSpacing: 40,
      navTitle: "Home",
      levelTitles: true,
      levelTitleAsBack: true,
      pushContent: "#container",
      labelClose: false,
      position: "left",
      levelOpen: "overlap",
    });
    Nav.open(2, 2);
  };

  const onHide = (e) => {
    setMedicoElegido(null);
    window.location.reload();
  };

  const aceptarClick = async (e) => {
    e.preventDefault();

    //Validar

    if (
      !medico ||
      medico.apellido === undefined ||
      medico.apellido === null ||
      medico.apellido.trim() === ""
    ) {
      mensajeAlerta("Registro Médico", "El apellido es necesario !.", "error");
      return;
    }

    if (
      !medico ||
      medico.nombre === undefined ||
      medico.nombre === null ||
      medico.nombre.trim() === ""
    ) {
      mensajeAlerta("Registro Médico", "El nombre es necesario !.", "error");
      return;
    }

    if (
      !medico ||
      medico.domicilio === undefined ||
      medico.domicilio === null ||
      medico.domicilio.trim() === ""
    ) {
      mensajeAlerta("Registro Médico", "El domicilio es necesario !.", "error");
      return;
    }

    if (!provinciaElegida) {
      mensajeAlerta("Registro Médico", "La provincia es necesaria !.", "error");
      return;
    }

    if (
      !medico ||
      medico.telefono === undefined ||
      medico.telefono === null ||
      medico.telefono.trim() === ""
    ) {
      mensajeAlerta("Registro Médico", "El teléfono es necesario !.", "error");
      return;
    }

    if (
      !medico ||
      medico.email === undefined ||
      medico.email === null ||
      medico.email.trim() === ""
    ) {
      mensajeAlerta("Registro Médico", "El email es necesario !.", "error");
      return;
    }

    if (
      !medico ||
      ((medico.mn === undefined ||
        medico.mn === null ||
        medico.mn.trim() === "") &&
        (medico.mp === undefined ||
          medico.mp === null ||
          medico.mp.trim() === ""))
    ) {
      mensajeAlerta("Registro Médico", "La matrícula es necesaria !.", "error");
      return;
    }

    if (
      !medico ||
      medico.numeroDocumento === undefined ||
      medico.numeroDocumento === null ||
      medico.numeroDocumento.trim() === ""
    ) {
      mensajeAlerta(
        "Registro Médico",
        "El Nº de Documento es necesario !.",
        "error"
      );
      return;
    }

    if (!tituloElegido) {
      mensajeAlerta("Registro Médico", "El título es necesario !.", "error");
      return;
    }

    if (!signature) {
      mensajeAlerta("Registro Médico", "La firma es necesaria !.", "error");
      return;
    }

    // Llamar al context

    const blob = dataURLtoBlob(signature);
    var formdata = new FormData();
    formdata.append("doctorWithLicense", "");
    formdata.append("license", "");
    formdata.append("signature", blob);
    formdata.append("province", provinciaElegida.nombre);
    formdata.append("address", medico.domicilio);
    formdata.append("workPhone", medico.telefono);
    formdata.append("specialty", null);
    formdata.append("title", tituloElegido.name);
    formdata.append("licenseNumber", Number(medico.mn));
    formdata.append("licenseType", "nacional");
    formdata.append("documentNumber", Number(medico.numeroDocumento));
    formdata.append("surname", medico.apellido);
    formdata.append("name", medico.nombre);
    formdata.append("password", "1234");
    formdata.append("email", medico.email);
    formdata.append("healthCenterId", configuracion.institucionId);
    formdata.append("id_medico", medicoElegido.id);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_RECETARIO_TOKEN}`,
      },
    };

    let datos = null;

    await fetch("https://external-api.recetario.com.ar/users", requestOptions)
      .then((response) => {
        datos = {
          recetarioId: response.id,
          medicoId: medicoElegido.id,
        };

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };

        axios
          .post(
            process.env.REACT_APP_BACKEND_URL +
              "/api/configuracion/actualizarmedicosrecetario",
            datos,
            config
          )
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .then((result) => {})
      .catch((error) => console.log("error", error));

    //onHide();
    //setSelectedRow(null);
    //exitClick();
  };

  const accesoDenegado = () => {
    mensajeAlerta(
      "Accesos",
      "No tiene permiso para acceder a esta pantalla !.",
      "error"
    );
    exitClick();
    return;
  };

  const clearSignature = () => {
    sigRef.current.clear();
    setSignature(null);
  };

  const handleSignatureEnd = () => {
    setSignature(sigRef.current.toDataURL());
  };
  //console.log(configuracion);

  return (
    <Fragment>
      <Barra />
      <Toast ref={toast} />
      <Toast ref={toastWarm} position="top-right" />
      <Fragment>
        {medicos ? (
          <Fragment>
            <Grid
              container
              spacing={3}
              sx={{ marginTop: { xs: "10vw", md: "1vw" }, marginLeft: "3vw" }}
            >
              <Grid item xs={12} md={12}>
                <Dropdown
                  value={medicoElegido}
                  onChange={(e) => {
                    if (e.value) {
                      setMedicoElegido(e.value);
                      guardarMedico({
                        ...medico,
                        domicilio: e.value.domicilio,
                        telefono: e.value.telefono,
                        email: e.value.email,
                        mn: e.value.mn,
                        mp: e.value.mp,
                        numeroDocumento: e.value.numeroDocumento,
                      });
                    } else {
                      setMedicoElegido(null);
                      setProvinciaElegida(null);
                      setTituloElegido(null);
                      guardarMedico({
                        ...medico,
                        domicilio: null,
                        telefono: null,
                        email: null,
                        mn: null,
                        mp: null,
                        numeroDocumento: null,
                      });
                    }
                  }}
                  options={medicos}
                  optionLabel="nombre"
                  showClear
                  placeholder="Seleccione un médico"
                  filter
                  className="w-full md:w-14rem"
                />
              </Grid>
              {medicoElegido &&
              configuracion.institucionId &&
              !medicoElegido.recetarioId ? (
                <Fragment>
                  <Grid item xs={12} md={6}>
                    <TextField
                      value={medico ? medico.apellido : null}
                      required
                      id="apellido"
                      label="Apellido"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          apellido: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <TextField
                      value={medico ? medico.nombre : null}
                      required
                      id="nombre"
                      label="Nombre"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          nombre: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={8}>
                    <TextField
                      value={medico ? medico.domicilio : null}
                      required
                      id="domicilio"
                      label="Domicilio"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          domicilio: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Dropdown
                      value={provinciaElegida}
                      onChange={(e) => setProvinciaElegida(e.value)}
                      options={provincias}
                      optionLabel="nombre"
                      placeholder="Seleccione una provincia"
                      className="w-full md:w-14rem"
                      showClear
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      value={medico ? medico.telefono : null}
                      required
                      id="telefono"
                      label="Teléfono"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          telefono: e.target.value,
                        })
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      value={medico ? medico.email : null}
                      required
                      id="email"
                      label="Email"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          email: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      value={medico ? medico.mn : null}
                      required
                      id="mn"
                      label="Matrícula nacional"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          mn: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      value={medico ? medico.mp : null}
                      required
                      id="mp"
                      label="Matrícula provincial"
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          mp: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      value={medico ? medico.numeroDocumento : null}
                      required
                      id="numeroDocumento"
                      label="D.N.I."
                      fullWidth
                      variant="standard"
                      onChange={(e) =>
                        guardarMedico({
                          ...medico,
                          numeroDocumento: e.target.value,
                        })
                      }
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <Dropdown
                      value={tituloElegido}
                      onChange={(e) => setTituloElegido(e.value)}
                      options={titulos}
                      optionLabel="name"
                      placeholder="Seleccione un título"
                      className="w-full md:w-14rem"
                      showClear
                    />
                  </Grid>

                  {/*      
                      <Grid item xs={12} md={12}>
                        <div className="file-select" id="src-file1">
                          <input
                            type="file"
                            name="src-file1"
                            aria-label="Archivo"
                            accept="image/*"
                            onChange={onChange}
                          />
                        </div>

                        <div
                          style={{ marginLeft: "1vw", display: "inline-block" }}
                        >
                          <label htmlFor="src-file1">{filename}</label>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Button
                          type="button"
                          icon="pi pi-upload"
                          onClick={uploadFile}
                          className="p-button-warning p-mr-2"
                          style={{ marginRight: "1em" }}
                          label="Upload"
                        />
                      </Grid>
                      */}

                  <Grid item xs={10} md={3}>
                    <h3>
                      Dibuje la firma
                      <button
                        style={{ marginLeft: "1vw" }}
                        onClick={clearSignature}
                      >
                        Limpiar
                      </button>
                    </h3>

                    <div
                      style={{
                        width: 300,
                        height: 200,
                        border: "1px solid black",
                      }}
                    >
                      <SignatureCanvas
                        penColor="black"
                        canvasProps={{
                          width: 300,
                          height: 200,
                          className: "sigCanvas",
                        }}
                        ref={sigRef}
                        onEnd={handleSignatureEnd}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}></Grid>

                  <Grid item xs={12} sm={12}>
                    <div>
                      <ButtonMui
                        onClick={aceptarClick}
                        color="primary"
                        type="button"
                        //fullWidth
                        variant="contained"
                        startIcon={<CheckIcon />}
                      >
                        Aceptar
                      </ButtonMui>
                      <ButtonMui
                        onClick={onHide}
                        color="secondary"
                        type="button"
                        //fullWidth
                        variant="contained"
                        startIcon={<CloseIcon />}
                        style={{ marginLeft: "1vw" }}
                        autoFocus
                      >
                        Cancelar
                      </ButtonMui>
                    </div>
                  </Grid>
                </Fragment>
              ) : null}
            </Grid>
          </Fragment>
        ) : (
          <Spinner />
        )}
        <Grid item xs={12} sm={12}></Grid>
      </Fragment>
    </Fragment>
  );
}

/* class="MuiDataGrid-cell MuiDataGrid-cellLeft" */
